import React from 'react'
import Layout from '../layouts/layout';
import Head from '../components/head';

const Contact = () => {
  return (
    <Layout>
      <Head title="Contact" />
      <h1>&lt;Contact /&gt;</h1>
      <p>If you want to hire me for your project, drop an email to <a href="mailto:mortuz@idevia.in">mortuz@idevia.in</a></p>
    </Layout>
  )
}

export default Contact
